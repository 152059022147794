import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { MenuItem } from '@/entities/menu-item/menu-item';
import {MenuitemService} from '@/services/menuitem';

const MODULE_NAME = 'menuitem';

@Module({ namespaced: true })
class MenuitemStore {

    public static readonly getModule = () => ({
        [MODULE_NAME]: MenuitemStore as any as VuexModule,
    })

    private menuItem!: MenuItem;

    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    public get MenuItem() {
        return this.menuItem;
    }

    // ------------------------------------------------------------------------
    // Actions are publicly accessbile wrappers to perform mutations
    // on the Store. These actions will internally call the appropriate
    // mutations to update the Store.
    //
    // Note: The returned value will be passed to the mutation handler
    // specified as the decorator's "commit" attribute.
    // ------------------------------------------------------------------------

    @Action({ commit: 'setMenuItem' })
    public async fetchMenuItem(id: number |undefined) {
        const result = await MenuitemService.getByid(id);

        return result;
    }


    // ------------------------------------------------------------------------
    // Mutations update the properties in the Store.
    // They are internal
    // ------------------------------------------------------------------------

    @Mutation
    private setMenuItem(value: MenuItem) {
        this.menuItem = value;
    }
}

export {
    MenuitemStore as default,
    MenuitemStore,
};
