import axios, { AxiosInstance } from 'axios';
import env from '@/config/env';

const { apiUrl } = env;
class BaseService {
  // --------------------------------------------------------------------------
  // Fields
  // --------------------------------------------------------------------------
  protected api: AxiosInstance;

  // --------------------------------------------------------------------------
  // Constructor
  // --------------------------------------------------------------------------

  constructor() {
    this.api = axios.create({ baseURL: apiUrl });
    // Set the AUTH token for any request
    this.api.interceptors.request.use((config) => {
      const token = sessionStorage.getItem('token');
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    });
  }

  // --------------------------------------------------------------------------
  // Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

export {
  BaseService,
};
