import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { InvoiceRequest } from '@/entities/invoice-request/invoice-request';
import { InvoiceRequestService } from '@/services/invoice-request-service';

const MODULE_NAME = 'invoicerequeststore';

@Module({ namespaced: true })
class InvoiceRequestStore {
    public static readonly getModule = () => ({
        [MODULE_NAME]: InvoiceRequestStore as any as VuexModule,
    })
    private invoice!: InvoiceRequest;

    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    public get Invoice() {
        return this.invoice;
    }

    // ------------------------------------------------------------------------
    // Actions are publicly accessbile wrappers to perform mutations
    // on the Store. These actions will internally call the appropriate
    // mutations to update the Store.
    //
    // Note: The returned value will be passed to the mutation handler
    // specified as the decorator's "commit" attribute.
    // ------------------------------------------------------------------------

    @Action({ commit: 'setInvoice' })
    public async fetchInvoice(data: InvoiceRequest) {
        const result = await InvoiceRequestService.PostData(data);
        return result;
    }

    @Action({ commit: 'setInvoice' })
    public async removeInvoice(id: number) {
        const result = await InvoiceRequestService.deteleData(id);
        return result;
    }


    // ------------------------------------------------------------------------
    // Mutations update the properties in the Store.
    // They are internal
    // ------------------------------------------------------------------------

    @Mutation
    private setInvoice(value: InvoiceRequest) {
        this.invoice = value;
    }


}

export {
    InvoiceRequestStore as default,
    InvoiceRequestStore,
};
