import { Package } from '@/entities/package/package';
import { PackageService } from '@/services/package';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

const MODULE_NAME = 'packagestore';
@Module({ namespaced: true })

class PackageStore {

    public static readonly getModule = () => ({
        [MODULE_NAME]: PackageStore as any as VuexModule,
    })

    private package!: Package;

    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    public get Package() {
        return this.package;
    }

    // ------------------------------------------------------------------------
    // Actions are publicly accessbile wrappers to perform mutations
    // on the Store. These actions will internally call the appropriate
    // mutations to update the Store.
    //
    // Note: The returned value will be passed to the mutation handler
    // specified as the decorator's "commit" attribute.
    // ------------------------------------------------------------------------

    @Action({ commit: 'createPackage' })
    public async addPackage(data: Package) {
        const results = await PackageService.create(data);
        return results;
    }


    @Action({ commit: 'editPackage' })
    public async editPack(data: Package) {
        const results = await PackageService.edit(data);
        return results;
    }

    // ------------------------------------------------------------------------
    // Mutations update the properties in the Store.
    // They are internal
    // ------------------------------------------------------------------------

    @Mutation
    private createPackage(value: Package) {
        this.package = value;
    }

    @Mutation
    private editPackage(value: Package) {
        this.package = value;
    }
}

export {
    PackageStore as default,
    PackageStore,
};
