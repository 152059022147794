
import { Package } from '@/entities/package/package';
import { AxiosResponse } from 'axios';
import { BaseService } from './base';

class PackageService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async create(data: Package): Promise<Package> {
    // define custom request options [NB: default config found in @/services/base]
    return  await (await this.api.post(`package/create`, data)).data;
  }


  public async edit(data: Package): Promise<Package> {
    // define custom request options [NB: default config found in @/services/base]
    return  await (await this.api.put(`package/edit`, data)).data;
  }
  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service  = new PackageService();

export {
  service as default,
  service as PackageService,
};
