import { AxiosResponse } from 'axios';
import { BaseService } from './base';

class DeliveryService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async PostData(data: any): Promise<unknown> {
    // define custom request options [NB: default config found in @/services/base]
    return  await (await this.api.post(`delivery/add`, data)).data;
  }

  public async getByUserId(id: number| undefined): Promise<unknown[]> {

    return  await (await this.api.get(`delivery/user/${id}`)).data;
  }

  public async getById(id: number| undefined): Promise<unknown[]> {

    return  await (await this.api.get(`delivery/${id}`)).data;
  }
  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service  = new DeliveryService();

export {
  service as default,
  service as DeliveryService,
};
