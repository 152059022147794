import { user } from '@/store';
import AppStore from '@/store/app';
import Vue from 'vue';
import Router, { NavigationGuardNext, Route } from 'vue-router';

Vue.use(Router);

function requireLogin() {
  return async (to: Route, from: Route, next: NavigationGuardNext) => {
    const isLoggedIn = await user.isLoggedIn();

    if (isLoggedIn) {
      next();
    } else {
      next('/login');
    }
  };
}

function guestOnly() {
  return async (to: Route, from: Route, next: NavigationGuardNext) => {
    const isLoggedIn = await user.isLoggedIn();

    if (isLoggedIn) {
      next({ name: 'dashboard' });
    } else {
      next();
    }
  };
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      beforeEnter: requireLogin(),
      meta: {

        layout: 'default',
      },
      component: () =>
        import(
          '@/pages/dashboard'),
    },
    {
      path: '/profile',
      name: 'profile',
      beforeEnter: requireLogin(),
      meta: {

        layout: 'default',
      },
      component: () =>
        import(
          '@/pages/profile'),
    },
    {
      path: '/points',
      name: 'Redeem Points',
      beforeEnter: requireLogin(),
      meta: {
        layout: 'default',
      },
      component: () =>
        import(
          '@/pages/redeem-points'),
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: guestOnly(),
      meta: {
        layout: 'minimal',
      },
      component: () =>
        import(
          '@/pages/login'),
    },
    {
      path: '/register',
      name: 'register',
      beforeEnter: guestOnly(),
      meta: {
        layout: 'minimal',
      },
      component: () =>
        import(
          '@/pages/register'),
    },
    {
      path: '/forget',
      name: 'forget',
      beforeEnter: guestOnly(),
      meta: {
        layout: 'minimal',
      },
      component: () =>
        import(
          '@/pages/forgetpassword'),
    },
    {
      path: '/package/show',
      name: 'Show Peralert',
      beforeEnter: requireLogin(),
      meta: {
        layout: 'default',
      },
      component: () =>
        import(
          '@/pages/show-per-alert'),
    },
    {
      path: '/delivery',
      beforeEnter: requireLogin(),
      name: 'delivery',
      meta: {
        layout: 'default',
      },
      component: () =>
        import(
          '@/pages/delivery'),
    },
    {
      path: '/delivery/create',
      beforeEnter: requireLogin(),
      name: 'create delivery',
      meta: {
        layout: 'default',
      },
      component: () =>
        import(
          '@/pages/add-delivery'),
    },
    {
      path: '/delivery/view/:id',
      beforeEnter: requireLogin(),
      name: 'delivery Detail',
      meta: {
        layout: 'default',
      },
      component: () =>
        import(
          '@/pages/deliverydetails'),
    },
    {
      path: '/reset/:token',
      beforeEnter: guestOnly(),
      name: 'reset',
      meta: {
        layout: 'minimal',
      },
      component: () =>
        import(
          '@/pages/reset-password'),
    },
    {
      path: '/package/create',
      beforeEnter: requireLogin(),
      name: 'create Pre-Alert',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/pre-alert'),

    },
    {
      path: '/authorized-pickup-users',
      beforeEnter: requireLogin(),
      name: 'Authorized Pickup Users',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/authorized-pickup-users'),

    },
    {
      path: '/create/authorized-pickup-users',
      beforeEnter: requireLogin(),
      name: 'Create Authorized Pickup Users',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/add-authorized-pickup-users'),

    },
    {
      path: '/package/:id',
      beforeEnter: requireLogin(),
      name: 'package',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/package'),

    },
    {
      path: '/history/',
      beforeEnter: requireLogin(),
      name: 'history',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/billing'),

    },
    {
      path: '/billing/:id',
      beforeEnter: requireLogin(),
      name: 'billing',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/billingdetail'),

    },
    {
      path: '/calculator',
      beforeEnter: requireLogin(),
      name: 'calculator',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/calculatorpage'),

    },
    {
      path: '*',
      meta: {
        layout: 'minimal',
      },
      component: () => import('@/pages/notfound'),

    }, {
      path: '/comming-soon',
      meta: {
        layout: 'default',
      },
      component: () => import('@/pages/comming-soon'),

    },
    {
      path: '/cal',
      meta: {
        layout: 'minimal',
      },
      component: () => import('@/components/calculator'),

    },

  ],
});

