import { AxiosResponse } from 'axios';
import { BaseService } from './base';
import { InvoiceRequest } from '@/entities/invoice-request/invoice-request';

class InvoiceRequestService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async PostData(data: InvoiceRequest): Promise<string> {
    // define custom request options [NB: default config found in @/services/base]
    return  await (await this.api.post(`api/invoice/add`, data)).data;
  }

  public async deteleData(id: number): Promise<string> {
    // define custom request options [NB: default config found in @/services/base]
    return  await (await this.api.delete(`api/invoice/remove/` + id)).data;
  }

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service  = new InvoiceRequestService();

export {
  service as default,
  service as InvoiceRequestService,
};
