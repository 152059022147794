import Vue from 'vue';
import App from '@/pages/app/app.vue';
import router from '@/config/router';
import store from '@/store';
import '@/config/register-service-worker';
import '@/theme/_all.scss';
import '@/config/vee-validate';
import Vue2Filters from 'vue2-filters';
import '@/modules/core/fontawesome';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

import VueSocialSharing from 'vue-social-sharing';

import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

Vue.use(VueSocialSharing);

Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday',
  ],
  dayOfWeekNamesShort: [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa',
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  timezone: 0,
});


Vue.config.productionTip = false;
Vue.use(Vue2Filters);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
