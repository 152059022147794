import { Authorizedusers } from '@/entities/authorizedusers/authorizedusers';
import { Authorizeded } from '@/services/authorizeded';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

const MODULE_NAME = 'menuitem';

@Module({ namespaced: true })


class AuthUserStore {


    public static readonly getModule = () => ({
        [MODULE_NAME]: AuthUserStore as any as VuexModule,
    })
    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    private _authorizedusers!: Authorizedusers[];

    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    public get authorizedusers() {
        return this._authorizedusers;
    }


    // ------------------------------------------------------------------------
    // Actions are publicly accessbile wrappers to perform mutations
    // on the Store. These actions will internally call the appropriate
    // mutations to update the Store.
    //
    // Note: The returned value will be passed to the mutation handler
    // specified as the decorator's "commit" attribute.
    // ------------------------------------------------------------------------

    @Action({ commit: 'adduthorizedUsers' })
    public async initializeAuthorizedUsers(id: number| undefined) {
        const results =  await Authorizeded.getByUserId(id);
        return results;
    }

    @Action({ commit: 'setAuthorizedUsers' })
    public async saveAuthorizedUsers(data: Authorizedusers) {
        const results =  await Authorizeded.postData(data);
        return results;
    }

    @Action({ commit: 'adduthorizedUsers' })
    public async removedAuthorizedUsers(id: number) {

        const results =  await Authorizeded.removedData(id);
        return results;
    }


    // ------------------------------------------------------------------------
    // Mutations update the properties in the Store.
    // They are internal
    // ------------------------------------------------------------------------

    @Mutation
    private adduthorizedUsers(value: Authorizedusers[]) {
        this._authorizedusers = value;
    }


    @Mutation
    private setAuthorizedUsers(value: Authorizedusers) {

    }
}

export {
    AuthUserStore as default,
    AuthUserStore,
};
