import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { User } from '@/entities/user/user';
import { LoginRespond } from '@/entities/login-respond/login-respond';
import { Login } from '@/services/auth-login';
import { UserServices } from '@/services/user-services';
import { Loginrequest } from '@/entities/loginrequest/loginrequest';
import { ResetRequest } from '@/entities/reset-request/reset-request';
import { Testimonial } from '@/entities/testimonial/testimonial';
import { Company } from '@/entities/company/company';
const MODULE_NAME = 'userstore';
@Module({ namespaced: true })
class UserStore {

    public static readonly getModule = () => ({
        [MODULE_NAME]: UserStore as any as VuexModule,
    })
    private user: User = {}  as User;

    private _islogined!: boolean;

    private loginresponse!: LoginRespond;

    private _company !: Company;

    private _testimonial: Testimonial[] = [];

    private value: number = 0;



    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    public get User() {
        return this.user;
    }

    public get Loginresponse() {
        return this.loginresponse;
    }

    public get testimonial() {
        return this._testimonial;
    }

    public get company() {
        return this._company;
    }


    // ------------------------------------------------------------------------
    // Actions are publicly accessbile wrappers to perform mutations
    // on the Store. These actions will internally call the appropriate
    // mutations to update the Store.
    //
    // Note: The returned value will be passed to the mutation handler
    // specified as the decorator's "commit" attribute.
    // ------------------------------------------------------------------------
    @Action({ commit: 'setisLogined' })
    public async isLoggedIn() {
        const { user } = this;
        let result = false;

        if (sessionStorage.getItem('token') !== null) {
            result = true;
        }
        return result;

    }
    @Action({ commit: 'setisLogined' })
    public async logout() {

        sessionStorage.removeItem('vuex');
        sessionStorage.removeItem('token');

        return false;
    }

    @Action({ commit: 'SetTtestimonial' })
    public async getTtestimonial(id: number) {

        const result = await UserServices.getAllTestimonial(id);
        return result;
    }

    @Action({ commit: 'setUser' })
    public async getUser(id: number) {
        const result = await UserServices.getByid(id);
        return result;
    }

    @Action({ commit: 'setUser' })
    public async updateProfile(data: User) {
        const result = await UserServices.updateProfile(data);
        return result;
    }


    @Action({ commit: 'setLogin' })
    public async Login(userdata: Loginrequest) {
        const result = await Login.Login(userdata);
        return result;
    }

    @Action({ commit: 'setpasswordrest' })
    public async forgetPassword(email: string) {
        const result = await UserServices.forgetPassword(email);
        return result;
    }

    @Action({ commit: 'setpasswordrest' })
    public async checkPasswordToken(email: string) {
        const result = await UserServices.checkPasswordToken(email);
        return result;
    }
    @Action({ commit: 'setpasswordrest' })
    public async resetPassword(data: ResetRequest) {
        const result = await UserServices.resetPassword(data);
        return result;
    }

    @Action({ commit: 'SetValue' })
    public async GetValue(id: number) {
        const result = await UserServices.getValue(id);
        return result;
    }

    @Action({ commit: 'SetCompany' })
    public async GetCompany(id: number) {
        const result = await UserServices.getCompany(id);
        return result;
    }






    // ------------------------------------------------------------------------
    // Mutations update the properties in the Store.
    // They are internal
    // ------------------------------------------------------------------------

    @Mutation
    private setUser(value: User) {
        this.user = value;
    }

    @Mutation
    private setLogin(value: LoginRespond) {
        this.loginresponse = value;
    }

    @Mutation
    private setisLogined(value: boolean) {
        this._islogined = value;
    }

    @Mutation
    private SetTtestimonial(value: Testimonial[]) {
        this._testimonial = value;
    }

    @Mutation
    private SetValue(v: number) {
        this.value = v;
    }

    @Mutation
    private SetCompany(v: Company) {
        this._company = v;
    }

    @Mutation
    private setpasswordrest(value: LoginRespond) {
        // TO DO
    }
}

export {
    UserStore as default,
    UserStore,
};
