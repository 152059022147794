import { AxiosResponse } from 'axios';
import { BaseService } from './base';
import { LoginRespond } from '@/entities/login-respond/login-respond';
import { Loginrequest } from '@/entities/loginrequest/loginrequest';

class LoginService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async  Login(userdata: Loginrequest): Promise<LoginRespond> {
    // define custom request options [NB: default config found in @/services/base]
    return  await (await this.api.post(`api/auth/signin`, userdata)).data;
  }

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service  = new LoginService();

export {
  service as default,
  service as Login,
};
