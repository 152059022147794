import { AxiosResponse } from 'axios';
import { BaseService } from './base';

import { User } from '@/entities/user/user';
import { Testimonial } from '@/entities/testimonial/testimonial';
import { ResetRequest } from '@/entities/reset-request/reset-request';
import { user } from '@/store';

class CalculatorService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  public async calculate(data: number): Promise<User> {

    return await (await this.api.post(`api/calculator/`, data)).data;
  }

  public async getDutylist(): Promise<User> {

    return await (await this.api.get(`api/calculator/duty`)).data;
  }






  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service = new CalculatorService();

export {
  service as default,
  service as CalculatorService,
};
