import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { PackageInvoice } from '@/entities/package-invoice/package-invoice';
import { PackageInvoiceService } from '@/services/package-invoice-service';


const MODULE_NAME = 'packageinvoice';

@Module({ namespaced: true })
class PackageInvoiceStore {
    public static fetchPackageInvoice(id: number | undefined) {
      throw new Error('Method not implemented.');
    }
    public static readonly getModule = () => ({
        [MODULE_NAME]: PackageInvoiceStore as any as VuexModule,
    })

    private packageInvoice!: PackageInvoice;

    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    public get PackageInvoice() {
        return this.packageInvoice;
    }

    // ------------------------------------------------------------------------
    // Actions are publicly accessbile wrappers to perform mutations
    // on the Store. These actions will internally call the appropriate
    // mutations to update the Store.
    //
    // Note: The returned value will be passed to the mutation handler
    // specified as the decorator's "commit" attribute.
    // ------------------------------------------------------------------------

    @Action({ commit: 'setAllPackageInvoice' })
    public async fetchPackageInvoice(id: number |undefined) {
        const result = await PackageInvoiceService.getByid(id);
        return result;
    }


    // ------------------------------------------------------------------------
    // Mutations update the properties in the Store.
    // They are internal
    // ------------------------------------------------------------------------

    @Mutation
    private setAllPackageInvoice(value: PackageInvoice) {
        this.packageInvoice = value;
    }
}

export {
    PackageInvoiceStore as default,
    PackageInvoiceStore,
};
