import { Authorizedusers } from '@/entities/authorizedusers/authorizedusers';
import { user } from '@/store';
import { AxiosResponse } from 'axios';
import { BaseService } from './base';

class Authorizeded extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async postData(data: Authorizedusers): Promise<Authorizedusers> {
    return await (await this.api.post(`authorizedpickup/add`, data)).data;

  }

  public async removedData(id: number| undefined): Promise<Authorizedusers> {
    return await (await this.api.delete(`authorizedpickup/remove/${id}/${user.User.id}`)).data;

  }


  public async getByUserId(id: number| undefined): Promise<Authorizedusers[]> {

    return  await (await this.api.get(`authorizedpickup/user/${id}`)).data;
  }

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service  = new Authorizeded();

export {
  service as default,
  service as Authorizeded,
};
