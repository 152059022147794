import { AxiosResponse } from 'axios';
import { BaseService } from './base';
import { PackageInvoice } from '@/entities/package-invoice/package-invoice';

class PackageInvoiceService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async getByid(id: number| undefined): Promise<PackageInvoice> {
    return  await (await this.api.get(`package/${id}`)).data;
  }

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

}

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------

const service  = new PackageInvoiceService();

export {
  service as default,
  service as PackageInvoiceService,
};
