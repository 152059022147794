import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
const MODULE_NAME = 'appstore';
@Module({ namespaced: true })
class AppStore {
  public static readonly getModule = () => ({
    [MODULE_NAME]: AppStore as any as VuexModule,
})
  private isLoading = false;
  public get Loading() {
    return this.isLoading;
  }

  @Action({ commit: 'SetLoading' })
  public ToolgeLoading(value: boolean) {
    return value;
  }

  @Mutation
  private SetLoading(value: boolean) {
    this.isLoading = value;
  }
}

export {
  AppStore as default,
  AppStore,
};
