import Vue from 'vue';
import Vuex, { ModuleTree } from 'vuex';
import { UserPackageStore } from './user-package/user-package';
import { UserStore } from './user/user';
import { PackageInvoiceStore } from './package-invoice/package-invoice';
import { PackageStore } from './package/package';

import { AppStore } from './app';
import { MenuitemStore } from './menu-item/menu-item';
import { InvoiceRequestStore } from './invoice-request-store/invoice-request-store';
import { createStoreWrapper } from '@/modules/core/store-wrapper';
import { AuthUserStore } from './authorizeded/authorizeded';

import { DeliveryStore } from './delivery-store/delivery-store';
import { CalculatorStore } from './calculator/calculator';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);


// Module Constants
// ----------------------------------------------------------------------------
const store = new Vuex.Store<any>({
  modules: {
    ...UserPackageStore.getModule(),
    ...AppStore.getModule(),
    ...UserStore.getModule(),
    ...MenuitemStore.getModule(),
    ...PackageInvoiceStore.getModule(),
    ...InvoiceRequestStore.getModule(),
    ...PackageStore.getModule(),
    ...AuthUserStore.getModule(),
    ...DeliveryStore.getModule(),
    ...CalculatorStore.getModule(),
    /* Import other custom modules here. */
  },
  plugins: [createPersistedState({ storage: window.localStorage })],
});

const getName = (T: { getModule(): {} }) => Object.keys(T.getModule())[0];
const userpackage = createStoreWrapper<UserPackageStore>(store, getName(UserPackageStore));
const user = createStoreWrapper<UserStore>(store, getName(UserStore));
const menuItems = createStoreWrapper<MenuitemStore>(store, getName(MenuitemStore));
const app = createStoreWrapper<AppStore>(store, getName(AppStore));
const packageInvoice = createStoreWrapper<PackageInvoiceStore>(store, getName(PackageInvoiceStore));
const invoicerequest = createStoreWrapper<InvoiceRequestStore>(store, getName(InvoiceRequestStore));
const package1 = createStoreWrapper<PackageStore>(store, getName(PackageStore));
const authuser = createStoreWrapper<AuthUserStore>(store, getName(AuthUserStore));
const deliveryStore = createStoreWrapper<DeliveryStore>(store, getName(DeliveryStore));
const calculatorStore = createStoreWrapper<CalculatorStore>(store, getName(CalculatorStore));
// ----------------------------------------------------------------------------
// Exports
// ----------------------------------------------------------------------------
export {
  store as default,
  userpackage,
  user,
  menuItems,
  app,
  packageInvoice,
  invoicerequest,
  package1,
  authuser,
  deliveryStore,
  calculatorStore,
};
