

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { UserPackageService } from '@/services/userpackageservice';
import { UserPackage } from '@/entities/user-package/user-package';


const MODULE_NAME = 'userpackage';

@Module({ namespaced: true })
class UserPackageStore {
    public static readonly getModule = () => ({
        [MODULE_NAME]: UserPackageStore as any as VuexModule,
    })

    private userpackage!: UserPackage;


    public get Userpackage() {
        return this.userpackage;
    }

    // ------------------------------------------------------------------------
    // Getters
    // ------------------------------------------------------------------------

    // ------------------------------------------------------------------------
    // Actions
    // ------------------------------------------------------------------------
    @Action({ commit: 'setAllUserPackage' })
    public async fetchUserPackage(id: number | undefined) {
        const result = await UserPackageService.getByid(id);

        return result;
    }


    // ------------------------------------------------------------------------
    // Mutations
    // ------------------------------------------------------------------------
    @Mutation
    private setAllUserPackage(values: UserPackage) {
        this.userpackage = values;
    }
}


export {
    UserPackageStore,
};
